<template>
  <div class="ads">
    <div class="adsForm">
      <el-button
        v-if="adType !== 'edit'"
        class="chatgpt-btn"
        type="text"
        size="mini"
        @click="openChatgptAd"
        >推荐广告语</el-button
      >
      <el-tabs
        v-model="activeTab"
        type="card"
        editable
        @edit="handleTabsEdit"
        @tab-click="handleClick"
        :before-leave="beforeLeave"
      >
        <el-tab-pane
          :key="index"
          v-for="(item, index) in adsTabs"
          :name="index + 1 + ''"
        >
          <!-- 受众表单 -->
          <span
            slot="label"
            class="adsLabel"
          >
            {{ adsList[index] | setName(index) }}
            <!-- <el-input v-model="adsList[index].name"></el-input> -->
          </span>
          <el-form
            :model="adsList[index]"
            :rules="rules"
            :ref="`ruleForm${index}`"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="选择主页："
              prop="page"
              class="pageSelect"
              require
            >
              <el-row>
                <el-col :span="10">
                  <el-select
                    v-model="adsList[index].page_id"
                    placeholder="请选择主页"
                    @change="selectPage"
                    filterable
                  >
                    <el-option
                      :label="page.name"
                      :value="page.id"
                      style="height: 70px"
                      v-for="page in accountPagesList"
                      :key="page.id"
                      :disabled="!page.is_published"
                    >
                      <div class="pageItems">
                        <div class="pageImg">
                          <el-image :src="page.picture_url"></el-image>
                        </div>
                        <div class="pageMsg">
                          <p>
                            <el-tooltip
                              content="检测到提示主页已经被封"
                              v-if="page.isBlocked"
                              ><i
                                class="el-icon-lock"
                                style="color: #f00"
                              ></i
                            ></el-tooltip>
                            <!-- <el-tooltip :content="`当前主页在投放中/审核中广告数量：${page.adsRunningCount}`"><span :style="{'color':`${page.adsRunningCount>200?'#f00':'#67C23A'}`}">（{{'#'+page.adsRunningCount}}）</span></el-tooltip> -->
                            {{ page.name }}
                          </p>
                          <div>编号：{{ page.id }}</div>
                        </div>
                      </div>
                      <set-page-status
                        :page="page"
                        @getList="getAcoountPages"
                      ></set-page-status>
                    </el-option>
                  </el-select>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="强制刷新主页，清除本地缓存主页数据"
                    placement="top"
                  >
                    <el-button
                      icon="el-icon-refresh"
                      @click="getAcoountPages(true)"
                      type="text"
                      size="mini"
                      :disabled="loadPages"
                    ></el-button>
                  </el-tooltip>
                  <div style="font-size: 12px; color: #999">
                    总容量：{{ adsList[index].totalAdsNum }}，已使用：{{ adsList[index].adsRunningNum }}
                  </div>
                </el-col>
                <el-col :span="14">
                  <span v-if="!isFbPageAd || isFbPagePostAd">
                    <span style="margin-left: 10px">Instagram账号：</span>
                    <el-select
                      :popper-append-to-body="false"
                      :class="!pageInstagramsList.length ? 'instagram-sel' : ''"
                      v-model="adsList[index].instagram_actor_id"
                      placeholder="请选择Instagram账号"
                    >
                      <el-option
                        :label="instagrams.username"
                        :value="instagrams.id"
                        v-for="instagrams in pageInstagramsList"
                        :key="instagrams.id"
                        style="height: 70px"
                      >
                        <div class="pageItems">
                          <div class="pageImg">
                            <el-image :src="instagrams.profile_pic"></el-image>
                          </div>
                          <div class="pageMsg">
                            <p>{{ instagrams.username }}</p>
                            <div>编号：{{ instagrams.id }}</div>
                          </div>
                        </div>
                      </el-option>
                    </el-select>
                  </span>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              label="广告样式："
              prop="page"
            >
              <span style="color: #999; margin-right: 20px">选择广告排序:</span>
              <el-switch
                v-model="leftToRight"
                active-text="从右往左"
                inactive-text="从左往右"
                active-value="rightToLeft"
                inactive-value="leftToRight"
              ></el-switch>
              <ads-tabs :adSeriesInfo="adSeriesInfo" :value="adsList[index]" @setCreative="setCreative" :adsList="adsList" :isFbPageAd="isFbPageAd" @setPostId="setPostId" :class="leftToRight" :rowThirdNum="rowThirdNum" :accountId="accountId"  :edit="edit" :index="index" ref="adsTab" @setPreviewIndex="(v)=>previewIndex = v"></ads-tabs>
              
            </el-form-item>
            <div v-if="isFbPageAd">
              <el-form-item
                label="广告正文"
                prop="message"
                v-if="adsList[index].media_type !== 'post'"
                :class="leftToRight"
              >
                <emoji-input
                  palce="正文：介绍广告内容"
                  type="textarea"
                  v-model="adsList[index].message"
                  :keyName="'message'"
                  @setValue="setValue"
                ></emoji-input>
                <!-- <el-input  v-model="adsList[index].message" type="textarea" placeholder="正文：介绍广告内容"></el-input> -->
              </el-form-item>
              <el-form-item
                label="行动号召"
                prop="action_type"
                v-if="adsList[index].media_type !== 'post' && isFbPagePostAd"
              >
                <span slot="label">
                  <span>行动号召</span>
                  <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    content="广告展示给用户时，会展示一个明确可点击的按钮，可以选择不用的文案。"
                  >
                    <i
                      class="el-icon-info"
                      slot="reference"
                      style="margin-left: 5px"
                    ></i>
                  </el-popover>
                </span>
                <el-select
                  v-model="adsList[index].action_type"
                  placeholder="请选择行动号召"
                >
                  <el-option
                    :label="action.desc"
                    :value="action.key"
                    v-for="action in callToActions"
                    :key="action.key"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="推广网址"
                prop="web_url"
                v-if="adsList[index].media_type !== 'post' && hasLink"
              >
                <span slot="label">
                  <span>推广网址</span>
                  <web-tips></web-tips>
                </span>
                <el-autocomplete
                  v-model="adsList[index].web_url"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入推广网址"
                  @select="selectwebLink"
                  style="width: 600px; margin-right: 10px"
                  @change="onInputWebLink"
                >
                  <template slot-scope="item">
                    <div>{{ item.item }}</div>
                  </template>
                </el-autocomplete>
                <el-button
                  type="text"
                  icon="el-icon-aim"
                  @click="openLink(adsList[index].web_url)"
                  :disabled="!adsList[index].web_url"
                  >预览</el-button
                >
                <p
                  :class="['tipsColor', checkLinkStatus.level]"
                  v-loading="checkLoading"
                >
                  {{ checkLinkStatus.message }}
                </p>
              </el-form-item>
            </div>
            <div
              v-if="
                (adsList[index].media_type == 'image' ||
                  adsList[index].media_type == 'muti' ||
                  adsList[index].media_type == 'video' ||
                  adsList[index].media_type == 'asset_feed') &&
                !isFbPageAd
              "
            >
              <el-form-item
                label=""
                prop="auto"
                v-if="adsList[index].media_type == 'muti'"
              >
                <el-switch
                  v-model="adsList[index].auto"
                  size="mini"
                ></el-switch>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;优先展示成效最佳的图片</span>
              </el-form-item>
              <el-form-item
                label="广告正文"
                prop="message"
                v-if="adsList[index].media_type == 'muti'"
                :class="leftToRight"
              >
                <emoji-input
                  palce="正文：介绍广告内容"
                  type="textarea"
                  v-model="adsList[index].message"
                  :keyName="'message'"
                  @setValue="setValue"
                ></emoji-input>
                <!-- <el-input  v-model="adsList[index].message" type="textarea" placeholder="正文：介绍广告内容"></el-input> -->
              </el-form-item>
              <action-type
                :initData="initData"
                :linkHistory="linkHistory"
                v-if="adsList[index].media_type == 'asset_feed'"
                v-model="adsList[index]"
              ></action-type>
              <div v-if="adsList[index].media_type != 'asset_feed'">
                <el-form-item
                  label="行动号召"
                  prop="action_type"
                >
                  <span slot="label">
                    <span>行动号召</span>
                    <el-popover
                      placement="top-start"
                      title=""
                      width="200"
                      trigger="hover"
                      content="广告展示给用户时，会展示一个明确可点击的按钮，可以选择不用的文案。"
                    >
                      <i
                        class="el-icon-info"
                        slot="reference"
                        style="margin-left: 5px"
                      ></i>
                    </el-popover>
                  </span>
                  <el-select
                    v-model="adsList[index].action_type"
                    placeholder="请选择行动号召"
                  >
                    <el-option
                      :label="action.desc"
                      :value="action.key"
                      v-for="action in callToActions"
                      :key="action.key"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="推广网址"
                  prop="web_url"
                >
                  <span slot="label">
                    <span>推广网址</span>
                    <web-tips></web-tips>
                  </span>
                  <el-autocomplete
                    v-model="adsList[index].web_url"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入推广网址"
                    @select="selectwebLink"
                    style="width: 600px; margin-right: 10px"
                    @change="onInputWebLink"
                  >
                    <template slot-scope="item">
                      <div>{{ item.item }}</div>
                    </template>
                  </el-autocomplete>
                  <el-button
                    type="text"
                    icon="el-icon-aim"
                    @click="openLink(adsList[index].web_url)"
                    :disabled="!adsList[index].web_url"
                    >预览</el-button
                  >
                  <p
                    :class="['tipsColor', checkLinkStatus.level]"
                    v-loading="checkLoading"
                  >
                    {{ checkLinkStatus.message }}
                  </p>
                </el-form-item>
              </div>
              <div v-if="edit">
                <el-form-item
                  label="设置网址转化追踪"
                  prop="page"
                >
                  <el-checkbox
                    value="1"
                    v-model="pixexCode"
                    style="margin-right: 15px"
                    disabled
                    >Facebook Pixel像素代码</el-checkbox
                  >
                  <el-select
                    v-model="adsList[index].fbPixelId"
                    disabled
                  >
                    <el-option
                      :label="item.name"
                      :value="item.pixel_id"
                      v-for="(item, index) in pixelList"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <span style="margin: 8px; color: #999">当前像素id：{{ adsList[index].fbPixelId }}</span>
                </el-form-item>
                <el-form-item
                  label="网址追踪"
                  prop="page"
                >
                  <el-input
                    v-model="adsList[index].url_tags"
                    placeholder="请输入推广网址"
                    type="textarea"
                    rows="6"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item label="网域">
                  <el-input
                    v-model="adsList[index].conversion_domain"
                    placeholder="网域仅包含一级域和二级域，如giikin.com"
                  ></el-input>
                </el-form-item>
                <el-form-item label="帖子Id">
                  <span style="margin: 8px; color: #999">{{ adsList[index].post_id }}</span>
                  <el-button
                    type="text"
                    size="mini"
                    @click="lookPost(adsList[index].post_id)"
                    >查看帖子</el-button
                  >
                </el-form-item>
              </div>
              <el-form-item
                label=""
                v-if="!creat"
              >
                <el-button
                  @click="showMore = !showMore"
                  size="mini"
                  type="text"
                  >{{ showMore ? '隐藏更多选项' : '显示更多选项' }}</el-button
                >
              </el-form-item>
              <el-form-item
                label="显示链接"
                v-if="showMore"
              >
                <el-input
                  v-model="adsList[index].show_url"
                  placeholder="请输入显示链接"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item
              label="帖子Id:"
              v-if="adsList[index].media_type == 'post' && edit"
            >
              <span style="margin: 8px; color: #999">{{ adsList[index].post_id }}</span>
              <el-button
                type="text"
                size="mini"
                @click="lookPost(adsList[index].post_id)"
                >查看帖子</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane name="-1">
          <span slot="label"><i class="el-icon-circle-plus-outline"></i> 添加创意</span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="previews">
      <div
        class="topPreviw"
        v-if="!edit || creat"
      >
        <div class="top-radio">
          <el-radio
            v-model="radio"
            label="1"
            :disabled="edit && !creat"
            >自动版位</el-radio
          >
          <el-radio
            v-model="radio"
            label="2"
            :disabled="edit && !creat"
            >选择版位</el-radio
          >
        </div>
        <div v-if="radio == 2">
          <el-select
            v-model="device_platforms"
            placeholder=""
            @change="selectPlacement"
            multiple
          >
            <el-option
              :label="item.desc"
              :value="item.key"
              v-for="item in initData.placement.DevicePlatform"
              :key="item.key"
            ></el-option>
          </el-select>
          <div
            class="tree"
            v-if="initData.placement"
          >
            <el-tree
              :data="platFormList.filter((v) => v.key == treeItem.key)"
              show-checkbox
              default-expand-all
              node-key="key"
              :props="defaultProps"
              :default-checked-keys="checkedNodes[treeItem.key + '_positions']"
              v-for="treeItem in platFormList"
              :key="treeItem.key"
              ref="plateTree"
            >
            </el-tree>
          </div>
        </div>
      </div>
      <div class="priewIframe">
          <preview-creative :currentCreative="adsList[this.activeIndex]" :initData="initData" :adSeriesInfo="adSeriesInfo" :campaign_objective="campaign_objective" :rowThirdNum="rowThirdNum" :accountId="accountId" :isFbPageAd="isFbPageAd" :edit="edit" :previewIndex="previewIndex"></preview-creative>
      </div>
    </div>
    <recommend-ad ref="recommendAd" />
  </div>
</template>
<script>
import imgList from './imgList';
import webTips from './webTips';
import draggable from 'vuedraggable';
import emojiInput from './emojiInput';
import moreSourceAd from './assetFeed/moreSourceAd.vue';
import actionType from './assetFeed/actionType.vue';
import tools from './tools.js';
import setPageStatus from './setPageStatus.vue';
import uploadVideo from './uploadVideo.vue';
import recommendAd from './recommendAd.vue';
import adsTabs from './adsComponents/adsTabs.vue';
import previewCreative from './adsComponents/previewCreative.vue';
import {
  upload,
  uploadUrl,
  accountPages,
  pageInstagrams,
  pagePosts,
  getPagePostById,
  historyInput,
  websiteUrlCheck,
  preview,
  accountPixel,
  pageVolumes,
} from '@/api/creatAd.js';
let _ = require('lodash');
const message = ['vue.draggable', 'draggable', 'draggable'];
import { mapState } from 'vuex';
export default {
  // props:['editAds','accountId','initData','adSeriesInfo','edit','adsInfo','pixelList','creat','activeStep'],
  props: {
    editAds: {
      type: Boolean,
      default: false,
    },
    accountId: {
      type: String,
      default: 0,
    },
    adSeriesInfo: {
      type: Object,
      default: () => {
        return { siteUrl: null };
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
    adsInfo: {
      type: Object,
      default: () => {},
    },
    pixelList: {
      type: Array,
      default: () => [],
    },
    creat: {
      type: Boolean,
      default: false,
    },
    activeStep: {
      type: Number,
      default: 3,
    },
    campaign_objective: {
      type: String,
      default: 'OUTCOME_SALES', //转化量
    },
    rowThirdNum: {
      type: String,
      default: '',
    },
    adType: {
      type: String,
      default: '',
    },
  },
  mixins: [tools],
  data() {
    return {
      pixexCode: true,
      activeTab: '1',
      activeIndex: 0,
      // adsTabs:[{}],
      editable: false,
      adsList: [
        {
          name: '单图1',
          web_url: '',
          copywriter: { title: '', content: '' },
          media_type: 'image',
          image: '',
          message: '',
          sk: 'timeline',
          action_type: this.initData?.ad ? this.initData.ad.CallToActionType[0].key : null,
          mutiImgs: [
            { name: '', fixed: false, copywriter: { title: '', content: '' }, link_url: '' },
            { name: '', fixed: false, copywriter: { title: '', content: '' }, link_url: '' },
            {
              name: '',
              fixed: false,
              copywriter: { title: '', content: '', link_url: '', totalAdsNum: null, adsRunningNum: null },
            },
          ],
        },
      ],
      showMore: false,
      rules: {
        action_type: { required: true, message: '行动号召不能为空！', trigger: ['blur', 'change'] },
        message: { required: false, message: '正文不能为空！', trigger: ['blur', 'change'] },
        web_url: { required: true, message: '推广网址不能为空！', trigger: ['blur', 'change'] },
        post_id: { required: true, message: '贴子不能为空！', trigger: ['blur', 'change'] },
        // web_url:{ validator:this.validWebUrl,trigger:"blur"}
      },
      list: [
        { name: '', fixed: false, copywriter: { title: '', content: '' } },
        { name: '', fixed: false, copywriter: { title: '', content: '' } },
        { name: '', fixed: false, copywriter: { title: '', content: '' } },
      ],
      // list: message.map((name, index) => {
      //     return { name, order: index + 1, fixed: false };
      // }),
      editSlideable: true,
      isDragging: false,
      delayedDragging: false,
      postList: [],
      value: '',
      radio: '1',
      postId: '',
      adStyleTab: 'img',
      data: [],
      defaultProps: {
        children: 'positions',
        label: 'desc',
      },
      type: 'img',
      accountPagesList: [],
      pageInstagramsList: [],
      platFormList: [],
      checkedNodes: {
        facebook_positions: [],
        instagram_positions: [],
        audience_network_positions: [],
        messenger_positions: [],
      },
      publisher_platforms: [],
      uploadTypes: 'img',
      uploadIndex: 0,
      chooseList: [],
      ad_format: 'MOBILE_FEED_STANDARD&0',
      checkLinkStatus: {},
      checkLoading: false,
      linkHistory: [],
      prevActiveType: 'image',
      imgType: '',
      fileType: 'mp4',
      leftToRight: 'leftToRight',
      device_platforms: ['mobile'],
      loadPages: false,
      // videoStatus:'processing',
      postMsg: '暂无数据',
      fbSearch: '', // fb 帖子搜索关键字
      fbTableLoading: false, // fb 帖子 列表 loading
      previewIndex:0,//预览组件的当前index
    };
  },
  components: {
    draggable,
    imgList,
    webTips,
    emojiInput,
    moreSourceAd,
    actionType,
    setPageStatus,
    uploadVideo,
    recommendAd,
    adsTabs,
    previewCreative
  },
  filters: {
    setName(v, index) {
      let interest = [];
      if (!v) {
        return '';
      }
      let text =
        v.media_type == 'image'
          ? '单图'
          : v.media_type == 'video'
          ? '视频'
          : v.media_type == 'post'
          ? '帖子'
          : v.media_type == 'asset_feed'
          ? '动态广告'
          : '轮播';
      // this.adsList[index].name = text+(index/1+1);
      return text + (index / 1 + 1);
    },
  },
  methods: {
    async fbHandleSearch() {
      // fb 帖子关键字搜索
      let page_id = this.adsList[this.activeIndex].page_id;
      this.fbTableLoading = true;
      let res = null;
      if (this.fbSearch) {
        let request = {
          postId: this.fbSearch,
          pageId: page_id,
        };
        res = await getPagePostById(request, this.rowThirdNum);
      } else {
        res = await pagePosts({ pageId: page_id }, this.rowThirdNum);
      }
      this.fbTableLoading = false;
      if (res.code == 0) {
        if (this.fbSearch) {
          this.postList = [res.data];
          this.$nextTick(() => {
            let item = this.adsList[this.activeIndex];
            item.post_id = this.fbSearch;
            this.$set(this.adsList, this.activeIndex, item);
          });
        } else {
          this.postList = res.data.list;
        }
      } else {
        (this.postList = []), (this.postMsg = res.data.comment);
      }
    },
    // 打开推荐广告语
    openChatgptAd() {
      // console.log(1)
      this.$refs.recommendAd.show();
    },
    // 查看帖子
    lookPost(id) {
      window.open(`https://www.facebook.com/${id}`, '_blank');
    },
    validWebUrl(rule, value, callback) {
      if (!value) {
        callback(new Error('推广网址不能为空！'));
      } else if (!this.$parent.checkUrl(value)) {
        callback(new Error('推广网址中的域名必须与第一步推广网址中的域名保持一致!'));
      } else {
        callback();
      }
    },
    // 切换创意
    beforeLeave(tab, newTab) {
      let flag = true;
      if (
        this.adsList[this.activeIndex].media_type == 'video' &&
        this.adsList[this.activeIndex].videoStatus != 'ready'
      ) {
        // flag = false
      } else {
        if (this.adsList.map((v) => v.media_type).includes('asset_feed')) {
          this.$message({
            type: 'warning',
            message: '一个动态素材广告组下面不允许创建多条广告',
          });
          flag = false;
        }
        // this.activeIndex = tab.index;
        // // 添加创意
        // if(tab.name == '-1'){
        //     this.adsTabs.push({});
        //     let obj = {name:'单图'+(this.activeIndex/1+1),web_url:this.adSeriesInfo.siteUrl,copywriter:{title:'',content:''},media_type:'image',image:'',message:'',sk:'timeline',action_type:this.initData.ad?this.initData.ad.CallToActionType[0].key:null,mutiImgs:[{name:'',fixed:false,copywriter:{title:'',content:''},link_url:this.adSeriesInfo.siteUrl},{name:'',fixed:false,copywriter:{title:'',content:''},link_url:this.adSeriesInfo.siteUrl},{name:'',fixed:false,copywriter:{title:'',content:'',link_url:''}}]};
        //     if(this.accountPagesList.filter(v=>v.is_published)){
        //         obj.page_id = this.accountPagesList.filter(v=>v.is_published)[0].id;
        //     }
        //     this.adsList.push(obj);
        //     this.selectPage(obj.page_id)
        //     this.activeTab = this.adsTabs.length +'';
        // }
      }
      // if(!this.iframeList[this.activeIndex]){
      //     this.debouncedPreviewIframe()
      // }
      if (!flag) {
        return false;
      } else {
        return true;
      }
    },
    close(v) {
      this[v] = false;
    },
    // 获取输入框内容
    setValue(key, val) {
      if (key == 'message') {
        this.adsList[this.activeIndex][key] = val;
      } else {
        this.adsList[this.activeIndex].copywriter[key] = val;
      }
      this.$forceUpdate();
      // console.log(this.adsList[this.activeIndex]);
    },
    // 输入网址校验
    selectwebLink(v) {
      // this.checkLoading = true;
      // console.log(v);
      this.onInputWebLink(v);
      this.adsList[this.activeIndex].web_url = v;
      this.$set(this.adsList, this.activeIndex, this.adsList[this.activeIndex]);
    },
    onInputWebLink(v) {
      // console.log('aaaaaa');
      if (!v) return false;
      this.checkLoading = true;
      websiteUrlCheck({ accountId: this.accountId, siteUrl: v }, this.rowThirdNum).then((res) => {
        // console.log(res);
        this.checkLoading = false;
        if (res.code == 0) {
          this.checkLinkStatus = res.data;
        }
      });
    },
    checkUrl(v) {
      // console.log(v);
      let reg = /^(https?:\/\/.[^?/\s]+)/;
      console.log(v.match(reg));
      let url = v.match(reg) ? v.match(reg)[0] : '';
      let web_url = this.adSeriesInfo.siteUrl;
      // console.log(url,web_url);
      let url1 = web_url.match(reg)[0];
      // console.log(url1);
      if (url1 !== url) {
        this.$message({
          type: 'warning',
          message: '网址有误,请检查网址',
        });
      }
    },
    // 点击检查网址
    openLink(link) {
      window.open(link);
    },
    // 输入网址提取帖子id
    setPostId(v) {
      let reg = /\d+\/posts\/\d+/;
      if (v.match(reg)) {
        let postMsg = v.match(reg)[0];
        let postId = postMsg.split('/posts/')[0] + '_' + postMsg.split('/posts/')[1];
        this.$set(this.adsList[this.activeIndex], 'post_id', postId);
      } else {
        this.$set(this.adsList[this.activeIndex], 'post_id', v);
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.linkHistory;
      var results = queryString
        ? restaurants.filter((n) => {
            return n.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 点击切换选项卡
    handleClick(tab) {
      //  if(this.adsList[this.activeIndex].media_type == 'video' && this.adsList[this.activeIndex].videoStatus != 'ready'){
      //     this.$message({
      //         type:'warning',
      //         message:'请耐心等待视频上传'
      //     })
      //     this.activeIndex = this.activeIndex;
      //     return false;
      // }

      // 添加创意
      if (tab.name == '-1') {
        if (this.adsList.map((v) => v.media_type).includes('asset_feed')) {
          this.activeIndex = this.activeIndex;
          return false;
        }
        this.adsTabs.push({});
        let obj = {
          name: '单图' + (this.adsTabs.length),
          web_url: this.adSeriesInfo.siteUrl,
          copywriter: { title: '', content: '' },
          media_type: 'image',
          image: '',
          message: '',
          sk: 'timeline',
          action_type: this.initData.ad ? this.initData.ad.CallToActionType[0].key : null,
          mutiImgs: [
            { name: '', fixed: false, copywriter: { title: '', content: '' }, link_url: this.adSeriesInfo.siteUrl },
            { name: '', fixed: false, copywriter: { title: '', content: '' }, link_url: this.adSeriesInfo.siteUrl },
            { name: '', fixed: false, copywriter: { title: '', content: '', link_url: '' } },
          ],
          fbAssetFeedSpec: {
            titles: [''],
            mDescriptions: [''],
            mBodies: [''],
            imgList: [],
            videos: [],
            mAdFormats: ['AUTOMATIC_FORMAT'],
            actionTypes: this.initData.ad ? [this.initData.ad.CallToActionType[0].key] : [],
            linkUrls: [this.adSeriesInfo.siteUrl],
          },
          imgList: [],
        };
        if (this.accountPagesList.filter((v) => v.is_published).length) {
          obj.page_id = this.accountPagesList.filter((v) => v.is_published)[0].id || 0;
        }
        this.adsList.push(obj);
        if (obj.page_id) {
          this.selectPage(obj.page_id);
        }
        this.activeTab = this.adsTabs.length + '';
      }
      this.activeIndex = tab.index;
    },
   
   
    // 克隆创意
    handleTabsEdit(targetName, action) {
      // 克隆创意
      let index;
      if (action === 'add') {
        index = this.activeTab / 1 - 1;
        // console.log(this.adsList[index]);
        let content = JSON.parse(JSON.stringify(this.adsList[index]));
        let text =
          content.media_type == 'image'
            ? '单图'
            : content.media_type == 'video'
            ? '视频'
            : content.media_type == 'post'
            ? '帖子'
            : content.media_type == 'asset_feed'
            ? '视频'
            : '轮播';
        content.name = text + (this.adsList.length + 1);
        let obj = { content: content };
        this.adsTabs.push({});
        this.adsList.push(content);
        this.activeTab = this.adsTabs.length + '';
      }

      // 删除创意
      if (action === 'remove') {
        if (this.adsList.length == 1) {
          this.$message({
            type: 'warning',
            message: '至少保留一个创意',
          });
          return false;
        }
        index = targetName / 1 - 1;
        this.adsTabs.splice(index, 1);
        this.adsList.splice(index, 1);
        this.activeTab = index + '';
      }
      if (targetName == 1) {
        this.activeIndex = 0;
        this.activeTab = '1';
      } else {
        this.activeIndex = this.activeTab / 1 - 1;
      }
      console.log(this.activeTab);
      console.log(this.adsList[this.activeIndex]);
    },
    //获取账户主页
    async getAcoountPages(flag) {
      this.loadPages = true;
      await accountPages({ accountId: this.accountId, force: flag }, this.rowThirdNum).then((res) => {
        // console.log(res);
        this.loadPages = false;
        let canUse = res.data.filter((v) => v.is_published);
        let notUse = res.data.filter((v) => !v.is_published);
        this.accountPagesList = canUse.concat(notUse);
        // console.log(this.adsInfo);
        if (!this.adsInfo) {
          let page = this.accountPagesList.find(v=>v.isDefaultPage)?this.accountPagesList.find(v=>v.isDefaultPage):this.accountPagesList.find((v) => v.is_published);
          // console.log(page);
          if (page) {
            this.adsList[this.activeIndex].page_id = page.id;
            this.adsList[this.activeIndex].page_url = page.picture_url;
            this.selectPage(page.id);
          }
        } else {
          // this.selectPage(this.adsInfo.page_id)
        }
      });
      // await accountPixel({accountId:this.accountId}).then(res=>{
      // console.log(res);
      //     this.pixelList = res.data;
      // });
    },
    // 点击选择主页
    selectPage(v) {
      // console.log(this.adsList);
      this.getPageVolumes(v);
      this.adsList[this.activeIndex].page_id = v;
      this.adsList[this.activeIndex].page_url = this.accountPagesList.filter((k) => k.id == v)[0].picture_url;
      this.$set(this.adsList[this.activeIndex], 'instagram_actor_id', '');
      // this.$set(this.adsList,this.activeIndex,this.adsList[this.activeIndex])
      this.$showLoading();
      pageInstagrams({ pageId: v, adAccountId: this.accountId }, this.rowThirdNum).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.pageInstagramsList = res.data || [];
          this.$set(this.adsList[this.activeIndex], 'instagram_actor_id', res.data.length ? res.data[0].id : '');
          this.adsList[this.activeIndex].insUrl = res.data.length ? res.data[0].profile_pic : '';
          this.$set(this.adsList, this.activeIndex, this.adsList[this.activeIndex]);
        }
      });
      if (this.adsList[this.activeIndex].media_type == 'post') {
        this.$refs.adsTab[this.activeIndex].getPostList();
      }
    },
    // 获取主页帖子容量
    getPageVolumes(v) {
      let params = {
        pageId: v,
        accountId: this.accountId,
      };
      pageVolumes(params, this.rowThirdNum).then((res) => {
        this.adsList[this.activeIndex].totalAdsNum = res.data.limit_on_ads_running_or_in_review;
        this.adsList[this.activeIndex].adsRunningNum = res.data.ads_running_or_in_review_count;
      });
    },
    // 获取帖子列表
    getPostList(v) {
      this.fbSearch = ''; // FB 帖子 搜索条件 初始化
      pagePosts({ pageId: v }, this.rowThirdNum).then((res) => {
        if (res.code == 0) {
          this.postList = res.data.list;
        } else {
          (this.postList = []), (this.postMsg = res.data.comment);
        }
      });
    },
    // 选择设备
    selectPlacement(val) {
      console.log(val);
      // this.$refs.vuetree.setCheckedNodes([]);
      this.checkedNodes = {
        facebook_positions: [],
        instagram_positions: [],
        audience_network_positions: [],
        messenger_positions: [],
      };
      let data = JSON.parse(JSON.stringify(this.initData.placement.PublisherPlatform));
      data.map((key, i) => {
        key.positions.map((item, m) => {
          if (item.devicePlatforms.some((v) => val.some((m) => v.key == m))) {
            item.disabled = false;
            // console.log(this.checkedNodes[key.key+'_positions']);
            this.checkedNodes[key.key + '_positions'].push(item.key);
          } else {
            item.disabled = true;
          }
          // console.log( this.checkedNodes);
          this.$set(key.positions, m, item);
        });
        this.$set(data, i, key);
        this.platFormList = data;
        // this.$refs.vuetree.setCheckedNodes(this.platFormList);
      });
      // this.$forceUpdate();
    },
    // 选择版位
    checkPlate(a, b, c) {
      console.log(a, b, c);
      // this.checkedNodes
    },
    // 点击帖子选中
    rowClick(row) {
      // console.log(row);
      this.postId = row.id;
    },
    // 获取文件后缀名
    getExtension(name) {
      return name.substring(name.lastIndexOf('.') + 1);
    },
    // 获取历史记录
    getHistory() {
      historyInput({ accountId: this.accountId, type: 'WEBSITE' }).then((res) => {
        this.linkHistory = res.data;
        // this.adsList[this.activeIndex].web_url = res.data[0]
      });
    },
    // 设置 creative
    setCreative(val,index) {
      this.$set(this.adsList, index, JSON.parse(JSON.stringify(val)));
    },
    setDefault(){
      this.adsList.forEach(creative=>{
        if (creative.media_type == 'muti' || creative.media_type == 'asset_feed' || creative.media_type == 'post') {
          // list.push(creative)
        } else {
          let imgListNew = JSON.parse(JSON.stringify(creative.imgList || []));
          creative.imgList.forEach((item,index) => {
            let msg = { copywriter: item.copywriter, message: item.message }
            if (!msg.copywriter) {
              msg.copywriter = {
                title: this.defaultTitle(imgListNew),
                content: this.defaultContent(imgListNew)
              }
            } else {
              msg.copywriter = {
                title: msg.copywriter.title || this.defaultTitle(imgListNew),
                content: msg.copywriter.content || this.defaultContent(imgListNew)
              }
            }
            msg.message = msg.message || this.defaultMessage(imgListNew)
            // item = {...item, copywriter: msg.copywriter, message: msg.message}
            console.log(msg.message,'msg.messagemsg.messagemsg.messagemsg.messagemsg.message');
            item.copywriter = msg.copywriter;
            item.message = msg.message
            console.log(item);
          })
          console.log(creative.imgList,'creative.imgListcreative.imgListcreative.imgListcreative.imgList');
        }
      })
      console.log(this.adsList,'this.adsListthis.adsListthis.adsListthis.adsList');
    }
  },
  created() {
  },
  watch: {
    // 监听受众的个数，是一个的时候，不能删除
    adsTabs: {
      handler() {
        if (this.adsTabs.length > 1) {
          this.editable = true;
        } else {
          this.editable = false;
        }
      },
      deep: true,
    },
    radio: {
      handler() {
        if (this.radio == 2) {
          this.platFormList = JSON.parse(JSON.stringify(this.initData.placement.PublisherPlatform));
          this.device_platforms = ['mobile'];
          this.selectPlacement(['mobile']);
        } else {
          this.device_platforms = [];
        }
      },
    },
    adSeriesInfo: {
      handler() {
        // console.log(this.adSeriesInfo.siteUrl);
        if (this.adSeriesInfo.siteUrl) {
          this.adsList.map((item, index) => {
            item.web_url = this.adSeriesInfo.siteUrl;
            item.mutiImgs.map((v, i) => {
              v.link_url = this.adSeriesInfo.siteUrl;
              this.$set(this.adsList, index, item);
            });
            this.$forceUpdate();
          });
        }
      },
      deep: true,
    },
    currentAdsMsg: {
      handler() {
        this.$emit('setAds', this.adsList);
        // let iframe = document.getElementById('iframe')
        // this.debouncedPreviewIframe(this.activeIndex);
      },
      deep: true,
    },
    adsInfo: {
      handler() {
        let adInfo = JSON.parse(JSON.stringify(this.adsInfo));
        this.getAcoountPages();
        // this.$set(this.iframeList, this.activeIndex, '');
        // adInfo.page = this.accountPagesList.filter(v=>v.id == adInfo.page_id)[0];
        this.adsList = [{ ...adInfo }];
        if (adInfo.page_id && this.adsList[this.activeIndex].media_type == 'post') {
          // this.getPostList(adInfo.page_id);
          // pagePosts({pageId:adInfo.page_id}).then(res=>{
          //     if(res.code == 0){
          //         this.postList = res.data.list;
          //     }else{
          //         this.postList = [];
          //     }
          // })
          // this.selectPage()
        }
      },
      deep: true,
      // immediate:true,
    },
    initData: {
      handler() {
        this.adsList[this.activeIndex].action_type = this.initData.ad.CallToActionType[0].key;
      },
      deep: true,
      immediate:true,
    },
    activeStep: {
      handler(v1,v2) {
        if (this.activeStep == 3 && !this.accountPagesList.length) {
          this.getAcoountPages();
        }
        if (this.activeStep == 4) {
          this.setDefault();
          
          // this.getAcoountPages();

        }
      },
    },
  },
  computed: {
    ...mapState('initData', ['initData']),
    
    adsTabs: {
      get() {
        return this.adsList.map((v) => v.name);
      },
      set() {},
    },
    currentAdsMsg() {
      console.log(this.adsList);
      // console.log(this.adsList[this.activeIndex]);
      let obj = { ...this.adsList[this.activeIndex] };
      let { uploadNums, totalAdsNum, adsRunningNum, ...pickObj } = obj;
      // console.log(pickObj);
      return pickObj;
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editSlideable,
        ghostClass: 'ghost',
      };
    },
    isFbPageAd() {
      console.log(this.adsInfo);
      return (
        this.adSeriesInfo.business == 'FACEBOOK_PAGE' ||
        this.campaign_objective == 'PAGE_LIKES' ||
        this.campaign_objective == 'POST_ENGAGEMENT'
      );
    },
    isFbPagePostAd() {
      return this.adSeriesInfo.objective == 'POST_ENGAGEMENT' || this.campaign_objective == 'POST_ENGAGEMENT';
    },
    callToActions() {
      let objective = this.adSeriesInfo.objective || this.campaign_objective;
      let business = objective == 'PAGE_LIKES' || objective == 'POST_ENGAGEMENT' ? 'FACEBOOK_PAGE' : '网站';
      if (this.initData && this.initData.ad && objective) {
        console.log(
          '((this.initData.ad.BusinessType.find(v=>v.key == business)).objectives.find(k=>k.key == objective))',
          this.initData.ad.BusinessType.find((v) => v.key == business).objectives.find((k) => k.key == objective),
        );
        const obj = this.initData.ad.BusinessType.find((v) => v.key == business).objectives.find(
          (k) => k.key == objective,
        );
        return obj ? obj.callToActions : [];
      } else {
        return [];
      }
    },
    hasLink() {
      if (this.callToActions.length) {
        return this.callToActions.find((v) => v.key == this.adsList[this.activeIndex].action_type)?.link;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // this.getAcoountPages();
    this.getHistory();
  },
};
</script>
<style lang="scss">
.adsLabel {
  width: 80px;
  display: inline-block;
  .el-input__inner {
    line-height: 28px;
    height: 28px;
  }
}
.rightToLeft {
  .el-input,
  .el-textarea {
    direction: rtl !important;
    unicode-bidi: bidi-override !important;
  }
}
.leftToRight {
  .el-input,
  .el-textarea {
    direction: ltr !important;
    unicode-bidi: bidi-override !important;
  }
}
.el-image-viewer__canvas {
  img {
    width: 800px;
    height: auto;
  }
}
.pageItems {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pageImg {
    width: 46px;
    height: 46px;
    flex: 46px 0 0;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
  .pageMsg {
    flex: 1;
    line-height: 1.5;
    padding-left: 8px;
    p {
      font-size: 14px;
      // color: #666;
    }
    div {
      font-size: 12px;
      // color: #999;
    }
  }
}
.pageSelect {
  margin-bottom: 5px;
  .el-select-dropdown__item {
    height: 46px;
  }
}

.ads {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .previews {
    flex: 300px 0 0;
    background: #fff;
    // background-color: #efefef;
    margin-left: 20px;
    .topPreviw {
      padding: 10px;
    }
    // padding: 10px;
    .top-radio {
      padding: 10px;
    }
    .el-select {
      width: 100%;
      line-height: 30px;
    }
    .tree {
      margin-top: 10px;
      max-height: 300px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
        // height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .priewIframe {
      margin-top: 10px;
      background: #fff;
      border-top: 5px solid #f5f5f5;
      padding: 10px;
      width: 370px;
      p {
        padding: 10px;
        font-size: 14px;
      }
      .iframe {
        width: 100%;
        margin-top: 10px;
        min-height: 300px;
        // height: 300px;
      }
    }
  }
  .flip-list-move {
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
  .no-move {
    -webkit-transition: -webkit-transform 0s;
    transition: -webkit-transform 0s;
    transition: transform 0s;
    transition: transform 0s, -webkit-transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
    width: 100%;
    overflow-x: scroll;
    /*滚动条样式*/
    &::-webkit-scrollbar {
      // width: 4px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    span {
      white-space: nowrap;
    }
  }
  .list-group-item {
    cursor: move;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    height: 420px;
    box-sizing: border-box;
    position: relative;
    .scrollItem {
      width: 246px;
    }
    .addItem {
      text-align: center;
      padding: 158px 10px;
      border: 1px dashed #ddd;
      cursor: pointer;
    }
    .deleteMuti {
      position: absolute;
      top: -10px;
      right: 0;
      z-index: 66;
    }
  }
  .list-group-item i {
    cursor: pointer;
  }
  .adsForm {
    padding: 20px;
    background: #fff;
    flex: 1;
    min-width: 800px;
    position: relative;
    .chatgpt-btn {
      position: absolute;
      top: 25px;
      right: 20px;
      z-index: 99;
    }
    .el-radio__label {
      font-size: 0;
    }
    .adCon {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      &.scrollItem {
        display: block;
        .imgForm {
          margin-left: 0;
          padding-top: 10px;
          height: 150px;
        }
      }
      .thumbList {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .thumbWrap {
          width: 120px;
          height: 120px;
          margin: 8px;
          cursor: pointer;
          position: relative;
          i {
            position: absolute;
            top: -5px;
            right: -5px;
            background: #333;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            text-align: center;
            line-height: 16px;
            color: #fff;
            cursor: pointer;
          }
        }
        .el-image {
          width: 100%;
          height: 100%;
        }
      }
      .imgCon {
        flex: 248px 0 0;
        // height: 248px;
        border: 1px dashed #ddd;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        background: #fff;
        position: relative;
        .imgTips {
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba($color: #000000, $alpha: 0.6);
          z-index: 222;
          padding: 10px;
          width: 100%;
          display: none;
          p {
            font-size: 12px;
            color: #fff;
            line-height: 1.5;
          }
        }
        &:hover {
          .tools {
            display: block;
            .el-icon-info {
              &:hover {
              }
            }
          }
        }

        .tools {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 22;
          display: none;
          i {
            font-size: 16px;
            margin: 5px;
            cursor: pointer;
          }
        }
        .el-image,
        video {
          width: 100%;
          height: 100%;
        }
        .el-upload__tip {
          text-align: left;
        }
      }
      .imgForm {
        flex: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .el-textarea__inner {
          height: 120px;
        }
      }
    }
  }
  .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child span.el-icon-close {
    display: none !important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
    // padding-right: 0;
    // border: none;
    color: #333;
  }
  .el-tabs__header {
    padding-right: 80px;
  }
  .el-tabs__new-tab {
    margin-right: 20px;
    border: none;
    outline: none;
    color: #666;
    width: 60px;
    font-size: 14px;
  }
  .el-tabs__new-tab {
    .el-icon-plus:before {
      content: '复制创意';
    }
  }
}
.instagram-sel {
  .el-select-dropdown {
    border: none;
  }
}
</style>
